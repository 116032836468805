import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { parse, isValid } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SideBar from "../../../components/sidebar/index.jsx";
import TopBar from "../../../components/topbar/index.jsx";
import Loader from "../../../components/loader/index.jsx";
import { AuthContext } from "../../../contexts/auth.jsx";
import { vendasRelatorioDetalhado } from "../../../services/venda/relatorio.jsx";
import { api, financeiroContasAPagar } from "../../../services/api.jsx";
import { DEFAULT_GRID_AUTOSIZE_OPTIONS, DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { getCurrentMonth } from '../../../function/getCurrentMonth.jsx';
import { format } from 'date-fns';


const ContasAPagar = () => {
    const [isLoading, setLoading] = useState(true);
    const { logout } = useContext(AuthContext);
    const [classStartDate, setClassStartDate] = useState('');
    const [styleStartDate, setStyleStartDate] = useState({ display: "none" });
    const [classEndDate, setClassEndDate] = useState('');
    const [styleEndDate, setStyleEndDate] = useState({ display: "none" });
    const [startDate, setStartDate] = useState(getCurrentDate('01'));
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [endDate, setEndDate] = useState(getCurrentDate);
    const [response, setResponse] = useState({ Lojas: [], Body: [] });
    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    function getCurrentDate(dia = '') {
        const currentDate = new Date();
        let day = dia ? dia : currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear();
        return `${year}-${month}-${day}`;
    }

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
        validaDatas(event.target.value, endDate);
    };

    const validaDatas = (startDate1, endDate1) => {
        const parsedStartDate = parse(startDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedStartDate)) {
            setClassStartDate('');
            setStyleStartDate({ display: 'none' });
            setBtnDisabled(false);
        } else {
            setClassStartDate('is-invalid');
            setStyleStartDate({ display: 'block' });
            setBtnDisabled(true);
        }

        const parsedEndDate = parse(endDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedEndDate)) {
            setClassEndDate('');
            setStyleEndDate({ display: 'none' });
        } else {
            setClassEndDate('is-invalid');
            setStyleEndDate({ display: 'block' });
        }

        if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
        validaDatas(startDate, event.target.value);
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        } else {
            Navigate("/login");
        }

        financeiroContasAPagarF().then(() => {
            setLoading(false);
        });
    }, []);

    const financeiroContasAPagarF = async () => {
        try {
            var response = await financeiroContasAPagar(formatarData(startDate, '00:00:00'), formatarData(endDate, '23:59:59'));
            setResponse(response.data);
        } catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    };

    function formatarData(dataString, hora) {
        const partes = dataString.split('-');
        const ano = partes[0];
        const mes = partes[1];
        const dia = partes[2];
        return `${mes}/${dia}/${ano} ${hora}`;
    }

    const handlerFiltrar = () => {
        setLoading(true);
        financeiroContasAPagarF().then(() => {
            setLoading(false);
        });
    };

    const columns = [

        { field: 'd_vencimento', headerName: 'Vencimento', width: 100 },
        { field: 'str_favorecido', headerName: 'Favorecido', width: 150 },
        { field: 'n_valor_pago', headerName: 'Valor Pago', type: 'number' },
        { field: 'n_valorparcela', headerName: 'Valor Parcela', type: 'number' },
        { field: 'n_valortotal', headerName: 'Valor Total', type: 'number' },
        { field: 'd_emissao', headerName: 'Emissão' },
        { field: 'd_competencia', headerName: 'Competencia' },
        { field: 'd_pagamento', headerName: 'Pagamento', width: 160 },
        // { field: 'vazio', headerName: ''},
        { field: 'str_numerodocumento', headerName: 'N Documento', width: 160 },
        { field: 'dh_entrega', headerName: 'Entrega', width: 160 },
        { field: 'dias_de_prazo', headerName: 'Dias de Prazo' },
        { field: 'cod_centro_de_custo', headerName: 'Cod Centro Custo' },
        { field: 'forma_de_pagto', headerName: 'Forma de Pagto', width: 160 },

        { field: 'str_cnpj', headerName: 'CNPJ' },
        { field: 'str_fantasia', headerName: 'Fantasia' },
        { field: 'str_conta_analitica_lacto', headerName: 'Conta Lacto' },
        { field: 'str_descricao_conta_lacto', headerName: 'Descricao Lacto' },
        { field: 'str_conta_analitica_contra', headerName: 'Contra' },
        { field: 'str_descricao_conta_contra', headerName: 'Descricao Contra' },
    ];

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{ tooltip: { title: 'Change density' } }}
                />
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'contas-a-pagar',
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            {isLoading && <Loader />}
            <div id="wrapper">
                <SideBar active="ContasAPagar" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <input
                                                        type="date"
                                                        className={"form-control " + classStartDate}
                                                        id="startDate"
                                                        name="startDate"
                                                        value={startDate}
                                                        onChange={handleStartDateChange}
                                                    />
                                                    <div className="invalid-tooltip" style={styleStartDate}>
                                                        Data inválida
                                                    </div>
                                                </div>
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <input
                                                        type="date"
                                                        className={"form-control " + classEndDate}
                                                        id="endDate"
                                                        name="endDate"
                                                        value={endDate}
                                                        onChange={handleEndDateChange}
                                                    />
                                                    <div className="invalid-tooltip" style={styleEndDate}>
                                                        Data inválida
                                                    </div>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary" disabled={btnDisabled}>Filtrar</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12">
                                                    <div style={{ height: 600, width: '100%' }}>
                                                        <DataGrid
                                                            rows={response}
                                                            columns={columns}
                                                            // columnVisibilityModel={{
                                                            //     idcloud: false,
                                                            //     n_codigoloja: false,
                                                            //     str_codigocliente: false,
                                                            //     n_codigovendedor: false,
                                                            // }}
                                                            pageSize={10}
                                                            autosizeOptions={DEFAULT_GRID_AUTOSIZE_OPTIONS}
                                                            rowsPerPageOptions={[10]}
                                                            filterModel={filterModel}
                                                            onFilterModelChange={(model) => setFilterModel(model)}
                                                            getRowId={(row) => row.str_numerodocumento}
                                                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                                            slots={{ toolbar: CustomToolbar }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default ContasAPagar;